import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactPage from "../components/contact/index"
const contact = () => {
    return (
        <Layout>
            <SEO
                title="Contact | Kentucky Tamil Sangam"
                desc="Contact | Kentucky Tamil Sangam"
                pathname="/contact"
                keywords="KYTS - Contact us"
            />
            <ContactPage />
        </Layout>
    )
}

export default contact
