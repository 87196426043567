import React, { Component } from "react"
import { Link } from "gatsby"
import apiRequest from "../../hooks/HttpRequest"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
export default class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: ""
    }
  }
  //handles form inputs change events
  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked ? true : false })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }
  contactUs = (e) => {
    e.preventDefault();
    const formData = new FormData()
    var foodItems = "";

    formData.append("name", this.state.name)
    formData.append("phone", this.state.phone)
    formData.append("email", this.state.email)
    formData.append("message", this.state.message)

    return axios
      .post(
        apiRequest.baseURL + "/FeddbackContact/SendMail",
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          this.setState({
            name: "",
            phone: "",
            email: "",
            message: ""
          })
          toast.success("Thanks for contacting us!. We shall contact you back.")
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  render() {
    return (
      <>

        {/* Start Page Title Area */}
        <div className="page-title-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Contact</h2>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Contact</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Title Area */}
        {/* Start Contact Area */}
        <ToastContainer />
        <section className="contact-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="contact-info-address">
                  <h3>Quick Contact </h3>

                  <div className="info-contact">
                    <i className="flaticon-call" />
                    <h3>Call Us</h3>
                    <span>
                      Alagar &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <a href="tel:+1(502)-322-6878">502-322-6878</a>
                    </span>
                    <br />
                    <span>
                      Kalpana &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <a href="tel:+1(502)-432-9142">502-432-9142</a>
                    </span>
                    <br />
                    <span>
                      Senthil T &nbsp;&nbsp;&nbsp;&nbsp;: <a href="tel:+1(502)-650-5920">502-650-5920</a>
                    </span>
                    <br />
                    <span>
                      Saravanan : <a href="tel:+1(502)-819-6579">502-819-6579</a>
                    </span>
                  </div>
                  <div className="info-contact">
                    <i className="flaticon-email" />
                    <h3>Email Us</h3>
                    <span>
                      <a href="mailto:kytamilsangam@gmail.com">kytamilsangam@gmail.com</a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-area">
                  <div className="contact-content">
                    <h3>Let’s talk!</h3>
                    <p>
                      Contact us.
                    </p>
                  </div>
                  <div className="contact-form">
                    <form id="contactForm" onSubmit={this.contactUs}>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              required
                              data-error="Please enter your name"
                              placeholder="Name"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              required
                              data-error="Please enter your number"
                              className="form-control"
                              placeholder="Phone"
                              value={this.state.phone}
                              onChange={this.handleChange}
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                              required
                              data-error="Please enter your email"
                              placeholder="Email"
                              value={this.state.email}
                              onChange={this.handleChange}
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              className="form-control"
                              id="message"
                              cols={30}
                              rows={6}
                              required
                              data-error="Write your message"
                              placeholder="Write Something"
                              value={this.state.message}
                              onChange={this.handleChange}
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="send-btn">
                            <button type="submit" className="default-btn">
                              Contact us
                              <i className="flaticon-right" />
                              <span />
                            </button>
                          </div>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          />
                          <div className="clearfix" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Area */}
        {/* Start Map Area */}
        <div className="map-section">
          <iframe title="contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.2799090714!2d-74.25987368715496!3d40.697670064588735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1570689677254!5m2!1sen!2sbd" />
        </div>
        {/* End Map Area */}

      </>
    )
  }
}
